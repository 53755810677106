<template>
  <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus">
    <base-modal
      v-model="isShowModal"
      v-loading="loading"
      class="base-translator-booking-details-modal"
      :class="getBem(blockClass)"
      :title="modalTitle"
      :width="isOnMobileViewport ? '95%' : '45%'"
    >
      <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a>

      <!-- Only visible during Printing -->
      <div :class="getBem(blockClass, 'print-header')">
        <picture>
          <source
            class="brand-logo"
            srcset="@/assets/images/brand-logo.webp"
            type="image/webp"
            :alt="$t('digitaltolk_logo')"
            width="220"
            height="44"
          />
          <img
            class="brand-logo"
            src="@/assets/images/brand-logo.png"
            :alt="$t('digitaltolk_logo')"
            width="220"
            height="44"
          />
        </picture>
        <h1>Bokn. ID # {{ booking.id }}</h1>
      </div>

      <template v-if="showDirectSalaryCard(booking)">
        <div :class="getBem(blockClass, 'direct-salary')">
          <el-card>
            <el-row>
              <el-col :md="2">
                <font-awesome-icon
                  :class="getBem(blockClass, 'direct-salary-icon')"
                  :icon="['fa', 'dollar-sign']"
                  size="2x"
                />
              </el-col>
              <el-col :md="22">
                <b>
                  {{ $t('pay_direct_salary') }}
                </b>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </template>

      <template v-if="isValidBooking && isShadowBooking">
        <div :class="getBem(blockClass, 'shadow-booking')">
          <el-card>
            <b :class="getBem(blockClass, 'shadow-booking-note')">
              {{ $t('shadow_booking_note', { booking_id: booking.parent_booking_id }) }}
            </b>
          </el-card>
        </div>
      </template>

      <template v-if="isBookingHasTimeSlots && [PENDING, TEMPORARY_PENDING].includes(booking.status_id)">
        <el-card>
          <p>
            {{ $t('booking_details_model_description_for_time_slots') }}
          </p>
        </el-card>
      </template>

      <table
        v-if="isValidBooking"
        v-loading="is_waiting"
        class="app-booking-details-table"
        :class="getBem(blockClass, 'table')"
        style="border-collapse: collapse"
      >
        <tbody>
          <!-- In review files -->
          <tr v-if="!empty(textTranslationPendingFiles) && $route.name === 'translator.bookings.potential'">
            <td :class="getBem(blockClass, 'table-label docs-list-label')">
              {{ $t('tt_label_documents_to_translate') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              <div v-for="item in textTranslationPendingFiles" :key="item.id" class="pending-files">
                <div class="item">
                  <span class="text-color-black bold">
                    {{ addDotsForLongerFileName(item.display_name, 24) }}
                  </span>
                </div>
                <div class="options">
                  <el-button type="app-button-default-o outlined " size="small" @click="downloadSelectedFile(item)">
                    {{ $t('view') }}
                  </el-button>
                </div>
              </div>
            </td>
          </tr>
          <!-- Booking ID row -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('booking_id') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.id }}
            </td>
          </tr>

          <!-- Reference row -->
          <tr v-if="booking.reference">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('booking_reference') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.reference }}
            </td>
          </tr>

          <!-- Booker Name row -->
          <tr v-if="booking.booker_name">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('booking_booker_name') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.booker_name }}
            </td>
          </tr>

          <!-- Additional Field Name row -->
          <tr v-if="booking.additional_field">
            <td :class="getBem(blockClass, 'table-label')">
              {{ bookingAdditionalFieldLabel }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.additional_field }}
            </td>
          </tr>

          <!-- Staff Name row -->
          <tr v-if="booking.staff_name">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('booking_staff_name') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.staff_name }}
            </td>
          </tr>

          <!-- Date -->
          <tr v-if="booking.type !== 'text_translation'">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('date') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.due_date }}
            </td>
          </tr>

          <!-- Start Time -->
          <tr v-if="booking.type !== 'text_translation'">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('start_time') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingStartTime() }}
            </td>
          </tr>

          <!-- End Time -->
          <tr v-if="booking.type !== 'text_translation'">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('end_time') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingEndTime() }}
            </td>
          </tr>

          <!-- Suggested times -->
          <tr v-if="booking.type !== 'text_translation' && booking.booking_time_slots.length && !isEmpty(booking)">
            <td :class="getBem(blockClass, 'table-label')">{{ $t('all_time_slots') }}</td>
            <td :class="getBem(blockClass, 'table-value')">
              <BookingTimeSlots :booking="booking"></BookingTimeSlots>
            </td>
          </tr>

          <!-- Translator Dead Line -->
          <template v-if="isBookingTypeTextTranslation">
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('text_translation_translator_deadline') }}
              </td>
              <td :class="isWithinFourDays(booking.text_translation.translator_deadline)">
                {{ booking.text_translation.translator_deadline }}
              </td>
            </tr>
          </template>

          <!-- Cancelled At row -->
          <tr v-if="isBookingCancelled">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('withdrawn_at') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.cancelled_at }}
            </td>
          </tr>

          <!-- Type row -->
          <tr v-if="!isBookingTypeVideo">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('type_of_interpretation') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{
                $t(`booking_type_${booking.type}`, {
                  type: subtype
                })
              }}
            </td>
          </tr>
          <!-- Booking Confidential -->
          <tr v-if="isBookingTypeTextTranslation">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('is_confidential') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.text_translation?.is_confidential ? $t('yes') : $t('no') }}
            </td>
          </tr>

          <!-- Booking Stamp -->
          <tr v-if="isBookingTypeTextTranslation">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('text_translation_need_to_be_stamp') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.text_translation?.is_stamped ? $t('yes') : $t('no') }}
            </td>
          </tr>

          <!-- Booking Delivery Method -->
          <tr v-if="isBookingTypeTextTranslation">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('tt_delivery_method') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ textTranslationBookingDeliveryMethod }}
            </td>
          </tr>

          <!-- Booking Instructions -->
          <tr v-if="isBookingTypeTextTranslation">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('instruction_booking_notes') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.text_translation?.descriptions }}
            </td>
          </tr>

          <tr v-if="isBookingTypeTextTranslation && booking?.translated_files_count > 0">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('tt_label_mode_of_submission') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{
                booking?.text_translation?.mode_of_submission
                  ? $t(`${booking.text_translation.mode_of_submission}`)
                  : '-'
              }}
            </td>
          </tr>

          <!-- Address row -->
          <tr v-if="isBookingTypeVideoPhysical || isBookingTypePhysical">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('address') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.address }}
            </td>
          </tr>

          <!-- Instruction row -->
          <tr
            v-if="
              !isBookingTypeConvey ||
              (isBookingTypeConvey && booking.status.code !== 'assigned' && booking.status.code !== 'completed')
            "
          >
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('booking_address_instructions') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.instructions }}
            </td>
          </tr>

          <!-- Customer Name row -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('customer') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingCustomer.name }}
            </td>
          </tr>

          <!-- Customer Mobile row -->
          <tr v-if="!isBookingTypeConvey">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('customer_mobile') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              <a v-if="isBookingTypeTextTranslation" :href="`tel:${booking.text_translation.phone}`">{{
                booking.text_translation.phone
              }}</a>
              <a v-else :href="`tel:${bookingCustomer.mobile}`">{{ bookingCustomer.mobile }}</a>
            </td>
          </tr>

          <!-- Customer Type row -->
          <tr v-if="!isBookingTypeConvey && !isBookingTypeTextTranslation">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('customer_type') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ $t('customer_type_' + bookingCustomer.customer_data.customer_type?.id) }}
            </td>
          </tr>

          <!-- Department row -->
          <tr v-if="!isBookingTypeConvey">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('department') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingCustomer.customer_data.department.name }}
            </td>
          </tr>

          <!-- Company row -->
          <tr v-if="bookingCompany">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('company') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingCompany }}
            </td>
          </tr>

          <!-- Municipality row -->
          <tr>
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('municipality') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ bookingCompanyMunicipality }}
            </td>
          </tr>

          <template v-if="isBookingWasTaken">
            <!-- Booking Session Time row -->
            <tr v-if="!isBookingTypeTextTranslation && !isBookingTypeConvey">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_session_time') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.session_time }}
              </td>
            </tr>

            <!-- Booking comment row -->
            <tr v-if="!isBookingTypeTextTranslation && !isBookingTypeConvey">
              <td :class="getBem(blockClass, 'table-label')">{{ $t('booking_session_time') }} - {{ $t('comment') }}</td>
              <td :class="getBem(blockClass, 'table-value')" :style="getWordStyle">
                {{ booking.session_comment_translator }}
              </td>
            </tr>

            <!-- Assigned Translator Name row -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('translator_name') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ bookingTranslator.name }}
              </td>
            </tr>

            <template v-if="!isBookingTypeTextTranslation">
              <!-- Assigned Translator Mobile row -->
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('translator_mobile') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <a :href="`tel:${bookingTranslator.mobile}`">{{ bookingTranslator.mobile }}</a>
                </td>
              </tr>
            </template>

            <!-- Assigned Travel Time row -->
            <tr v-if="isBookingTypePhysical">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('translator_max_travel_time') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ maxTravelTime }}
              </td>
            </tr>

            <!-- Assigned Travel Time row -->
            <tr v-if="isBookingTypePhysical">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('translator_max_travel_km') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ maxTravelKM }}
              </td>
            </tr>
          </template>

          <!-- Language row -->
          <template v-if="isBookingTypeTextTranslation">
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('text_translation_from_language_id') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ languageOpts[booking.from_language_id] }}
              </td>
            </tr>
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('text_translation_to_language_id') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ languageOpts[booking.to_language_id] }}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('language') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ languageOpts[booking.from_language_id] }}
              </td>
            </tr>
          </template>

          <!-- Video -->
          <template v-if="isBookingTypeVideo">
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('type_of_interpretation') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ $t(bookingVideo.provider) }}
              </td>
            </tr>
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('video_url') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                <a :href="generateUrl(bookingVideo)" target="_blank">
                  {{ bookingVideo.provider === 'jitsi' ? $t('open_video_url') : bookingVideo.url }}
                </a>
              </td>
            </tr>
          </template>

          <!-- Gender row -->
          <tr v-if="!isBookingTypeTextTranslation">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('gender') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ formatGender(booking.gender) }}
            </td>
          </tr>

          <!-- Convey Booking Details -->
          <template v-if="isBookingTypeConvey && isBookingWasTaken && isValidConveyBooking">
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('message_to_convey') }}
              </td>
              <td class="wordArrange" :class="getBem(blockClass, 'table-value')">
                {{ booking.convey.message }}
              </td>
            </tr>
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('additional_info') }}
              </td>
              <td class="wordArrange" :class="getBem(blockClass, 'table-value')">
                {{ booking.convey.additional_info }}
              </td>
            </tr>
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('patient_phone') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                <a :href="`tel:${booking.convey.phone}`">{{ booking.convey.phone }}</a>
              </td>
            </tr>
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('convey_files') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                <div v-for="item in booking.convey.files" :key="item.id" class="fLists">
                  <div class="item">
                    <span class="text-color-success text-underline">
                      <el-icon><DocumentCopy /></el-icon>
                      {{ addDotsForLongerFileName(item.display_name, 24) }}
                    </span>
                  </div>
                  <div class="options">
                    <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                      <svg
                        width="15px"
                        height="15px"
                        fill="#555555"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                        />
                      </svg>
                    </el-button>
                    <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                      <svg
                        width="15px"
                        height="15px"
                        fill="#ffffff"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                        />
                      </svg>
                    </el-button>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <!-- Booking Message -->
          <template v-if="hasMessage">
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_message') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.messages.message === '----' ? '' : booking.messages.message }}
              </td>
            </tr>

            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('message_files') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                <div v-for="item in booking.messages.files" :key="item.id" class="fLists">
                  <div class="item">
                    <span class="text-color-success text-underline">
                      <!-- <i class="el-icon-document-copy" /> -->
                      <el-icon><DocumentCopy /></el-icon>
                      {{ addDotsForLongerFileName(item.display_name, 24) }}
                    </span>
                  </div>
                  <div class="options">
                    <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                      <svg
                        width="15px"
                        height="15px"
                        fill="#555555"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                        />
                      </svg>
                    </el-button>
                    <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                      <svg
                        width="15px"
                        height="15px"
                        fill="#ffffff"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                        />
                      </svg>
                    </el-button>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <template v-if="isBookingTypeTextTranslation">
            <!-- Translator Language Pair Levels -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('translator_level') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ bookingTranslatorLanguagePairLevel }}
              </td>
            </tr>
            <template v-if="isBookingWasTaken">
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('instruction_booking_notes') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ booking.text_translation.descriptions }}
                </td>
              </tr>

              <template
                v-if="
                  !empty(booking.text_translation) &&
                  !empty(booking.text_translation.additional_text) &&
                  empty(textTranslationReviewFiles)
                "
              >
                <tr>
                  <td :class="getBem(blockClass, 'table-label')">
                    {{ $t('text_translation_additional_text') }}
                  </td>
                  <td :class="getBem(blockClass, 'table-value')">
                    {{ booking.text_translation.additional_text }}
                  </td>
                </tr>
              </template>
              <tr v-if="!empty(textTranslationReviewFiles)">
                <td :class="getBem(blockClass, 'table-label')">
                  <el-icon>
                    <Document />
                  </el-icon>
                </td>
                <!-- Trans Target Text Translation -->
                <template v-if="isBookingTypeTextTranslation">
                  <td :class="getBem(blockClass, 'table-value')">
                    <div class="item row" :style="{ 'flex-direction': isOnMobileViewport ? 'column' : 'row' }">
                      <el-tag v-if="booking.rejected_files_count" class="rejected-docs-tag" type="danger">
                        {{ booking.rejected_files_count }} {{ $t('document_rejected') }}
                      </el-tag>
                      <el-button
                        v-if="
                          booking.text_translation.mode_of_submission === 'manual' &&
                          booking?.translated_files_count > 0
                        "
                        type=""
                        size="medium"
                        color="black"
                        class="text-color-white link"
                        @click="() => toggleTranslationModesDialog(3)"
                      >
                        {{ $t('tt_label_see_documents') }}
                      </el-button>
                      <el-button
                        v-else-if="
                          booking.text_translation.mode_of_submission === 'trans_target' &&
                          booking?.translated_files_count > 0
                        "
                        type=""
                        size="medium"
                        color="black"
                        class="text-color-white link"
                        @click="handleFileClick(textTranslationReviewFiles[0], true)"
                      >
                        <img src="@/assets/images/external-link.png" :alt="$t('trans-target-link')" />
                        {{ $t('tt_label_review_document') }}
                      </el-button>
                      <el-button
                        v-else-if="booking?.translated_files_count <= 0"
                        type=""
                        size="medium"
                        color="black"
                        class="text-color-white link"
                        @click="() => toggleTranslationModesDialog(1)"
                      >
                        {{ $t('tt_label_start_translation') }}
                        <img
                          style="margin-left: 5px"
                          src="@/assets/images/external-link.png"
                          :alt="$t('trans-target-link')"
                        />
                      </el-button>
                      <el-button
                        type=""
                        size="medium"
                        color="white"
                        class="text-color-black learn-more-btn"
                        @click="openVideoLink"
                      >
                        {{ $t('tt_label_learn_more') }}
                      </el-button>
                    </div>
                  </td>
                </template>
                <template v-else>
                  <td :class="getBem(blockClass, 'table-value')">
                    <div v-for="item in textTranslationReviewFiles" :key="item.id" class="fLists">
                      <div class="item">
                        <span class="text-color-success text-underline">
                          <el-icon><DocumentCopy /></el-icon>
                          {{ addDotsForLongerFileName(item.display_name, 24) }}
                        </span>
                      </div>
                      <div class="options">
                        <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                          <svg
                            width="15px"
                            height="15px"
                            fill="#555555"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path
                              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                            />
                          </svg>
                        </el-button>
                        <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                          <svg
                            width="15px"
                            height="15px"
                            fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path
                              d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                            />
                          </svg>
                        </el-button>
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
              <tr v-if="!empty(textTranslationCompletedFiles)">
                <td :class="getBem(blockClass, 'table-label')">
                  <!-- <i class="el-icon-document-checked" /> -->
                  <el-icon>
                    <DocumentChecked />
                  </el-icon>
                  {{ $t('completed_file') }}

                  <div v-if="hasMultipleNonVersionedFiles" class="nonversioned-files-info">
                    <el-alert
                      :closable="false"
                      :title="$t('tt_label_info_regarding_multiple_versioned_files')"
                      type="info"
                      :show-icon="true"
                    />
                  </div>
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <div v-for="item in textTranslationCompletedFiles" :key="item.id" class="fLists">
                    <div class="item">
                      <span class="text-color-success text-underline">
                        <!-- <i class="el-icon-document-copy" /> -->
                        <el-icon><DocumentCopy /></el-icon>
                        {{ addDotsForLongerFileName(item.display_name, 24) }}
                      </span>
                    </div>
                    <div class="options">
                      <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#555555"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0
                            24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8
                            192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5
                            68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64
                            64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2
                            66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4
                            11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                          />
                        </svg>
                      </el-button>
                      <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#ffffff"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4
                            71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0
                            12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80
                            80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39
                            39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0
                            33.9z"
                          />
                        </svg>
                      </el-button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <!-- Text Translation Total Words -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{
                  booking.status.id === COMPLETED || booking.status.id === IN_REVIEW
                    ? $t('tt_actual_word_count')
                    : $t('tt_approximate_word_count')
                }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ textTranslationApproximateWordsCount }}
                <el-tag v-if="booking.status.id === IN_REVIEW" style="margin-left: 2rem" size="medium" type="warning">
                  {{ $t('tt_status_in_review') }}
                </el-tag>
              </td>
            </tr>
            <!-- Text Translation Formating hours -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('tt_formatting_hours') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.text_translation.hours_by_translator }}
                <el-tag v-if="booking.status.id === IN_REVIEW" size="medium" type="warning" style="margin-left: 2rem">
                  {{ $t('tt_status_in_review') }}
                </el-tag>
              </td>
            </tr>
          </template>
          <template v-if="!isBookingTypeTextTranslation">
            <!-- Translator Levels -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('translator_levels') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                <ul :class="getBem(blockClass, 'table-value-translator-levels')">
                  <li v-for="(level, idx) in booking.translator_levels" :key="idx">
                    {{ formatTranslatorLevel(level.translator_level_id) }}
                  </li>
                </ul>
              </td>
            </tr>
          </template>

          <!-- Price-list link -->
          <tr v-if="!isUserEmployedTranslator">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('price_list') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              <a href="#" @click="handlePriceListLinkClick(booking.id, $event)">
                {{ $t('click_here_to_see_price_list') }}
              </a>
              &nbsp;<i v-show="loading" class="el-icon-loading" />
            </td>
          </tr>

          <tr v-if="!isBookingTypeTextTranslation && booking.translator_trainings.length > 0">
            <td :class="getBem(blockClass, 'table-label')">
              {{ $t('required_course') }}
            </td>
            <td :class="getBem(blockClass, 'table-value')">
              {{ booking.translator_trainings.map((i) => i.name).join(', ') }}
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <!--      <div v-if="isBookingTypePhysical"></div>-->
      <div v-if="!isBookingTypePhysical" :class="getBem(blockClass, 'actions')" class="text-right">
        <el-button
          class="app-button-secondary"
          :class="getBem(blockClass, 'print-button')"
          icon="Printer"
          tabindex="0"
          @click="handleClickPrint"
        >
          {{ $t('btn_print') }}
        </el-button>

        <el-button
          class="app-button-default"
          :class="getBem(blockClass, 'close-button')"
          tabindex="0"
          @click="handleClickClose"
        >
          {{ $t('btn_close') }}
        </el-button>
      </div>
    </base-modal>
    <translation-modes-setup-modal
      ref="translationModesSetupModal"
      :booking="booking"
      @auto-translation="handleFileClick(textTranslationReviewFiles[0], true)"
      @manual-form-submission="open(booking, false)"
    />
    <!-- Component DIV Wrapper -->
  </focus-trap>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import findKey from 'lodash/findKey'
import includes from 'lodash/includes'
import getTranslatorLevels from '@/modules/constants/translatorLevel'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BookingTimeSlots from '../partials/BookingTimeSlots'
import { formatTime } from '@/modules/helpers/common'
import { PENDING, TEMPORARY_PENDING, COMPLETED, IN_REVIEW } from '@/modules/constants/bookingStatus'
import API, { TRANS_TARGET_URL } from '@/modules/constants/api'
import { apiCall } from '@/modules/helpers/vuexActions'
import { FINANCIAL } from '../../../constants/api'
import { showSmallErrorNotif } from '../../../helpers/notifications'

import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { download, showFile } from '@/modules/helpers/file'
import TranslationModesSetupModal from './TranslationModesSetupModal.vue'

import LS from '@/modules/constants/localStorage'
import appStorage from '@/modules/helpers/appStorage'

library.add(faDollarSign)

export default {
  components: {
    BookingTimeSlots,
    FontAwesomeIcon,
    TranslationModesSetupModal
  },

  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    title: {
      type: String,
      default: ''
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'translator-booking-details-modal',
      isShowModal: false,
      booking: {},
      transTargetUrl: TRANS_TARGET_URL,
      loading: false,
      PENDING,
      TEMPORARY_PENDING,
      COMPLETED,
      IN_REVIEW,
      is_waiting: false
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    ...mapGetters('booking', ['languageOpts', 'bcfConfig']),
    ...mapGetters('auth', ['isUserCustomer', 'isUserTranslator', 'isUserEmployedTranslator', 'user']),

    isOnMobileViewport() {
      return this.$store.getters['auth/isOnMobileViewport']
    },

    isBookingHasTimeSlots() {
      return !isEmpty(this.booking.booking_time_slots)
    },

    getWordStyle() {
      return {
        'word-break': 'normal'
      }
    },
    /**
     * @returns {string}
     */
    modalTitle() {
      return this.title !== '' ? this.title : this.$t('booking_details')
    },

    /**
     * @returns {string}
     */
    bookingCompany() {
      return this.booking?.customer?.department?.company?.name ?? ''
    },

    /**
     * @returns {string}
     */
    bookingCompanyMunicipality() {
      return (
        this.booking?.customer?.department?.company?.municipality?.name ??
        this.booking?.customer?.customer_data?.department?.company?.municipality?.name
      )
    },

    /**
     * Returns boolean whether the booking details is valid to be displayed.
     *
     * @return {Boolean}
     */
    isValidBooking() {
      return !isEmpty(this.booking)
    },

    /**
     * Returns boolean whether the convey booking details is valid to be displayed.
     *
     * @return {Boolean}
     */
    isValidConveyBooking() {
      return !isEmpty(this.booking.convey)
    },

    /**
     * Returns boolean whether if the booking was entertained by the Translator at some point.
     *
     * @returns {Boolean}
     */
    isBookingWasTaken() {
      return (
        this.booking.status.code !== 'pending' &&
        this.booking.status.code !== 'expired' &&
        this.booking.status.code !== 'cancelled' &&
        this.booking.status.code !== 'late_cancelled' &&
        this.booking.status.code !== 'late_cancelled_customer'
      )
    },

    /**
     * Returns boolean whether if the booking type is video.
     *
     * @returns {boolean}
     */
    isBookingTypeVideo() {
      return ['video', 'video_skype', 'video_emergency'].includes(this.booking.type)
    },

    /**
     * Returns boolean whether if the booking type is physical.
     *
     * @returns {boolean}
     */
    isBookingTypePhysical() {
      return String(this.booking.type) === 'physical'
    },

    /**
     * Returns boolean whether if the booking type is phone.
     *
     * @returns {boolean}
     */
    isBookingTypePhone() {
      return String(this.booking.type) === 'phone'
    },

    /**
     * Returns the fail proof value for booking.video_booking.
     *
     * @returns {object}
     */
    bookingVideo() {
      if (!isNil(this.booking) && !isNil(this.booking.video_booking) && !isEmpty(this.booking.video_booking)) {
        return this.booking.video_booking
      } else {
        // Define value placeholders in here.
        return {}
      }
    },

    /**
     * Returns boolean if the booking was cancelled.
     *
     * @returns {Boolean}
     */
    isBookingCancelled() {
      return !isNil(this.booking.cancelled_at)
    },

    /**
     * Computed value for booking customer.
     * @returns {*}
     */
    bookingCustomer() {
      return isArray(this.booking.customer) ? this.booking.customer[0] : this.booking.customer
    },

    /**
     * Computed value for Max Travel Time.
     * @returns {String}
     */
    maxTravelTime() {
      if (isNil(this.booking.travel_time)) return this.$t('not_specified')
      const maxTravel = this.booking.travel_time

      return !isNil(maxTravel)
        ? moment().startOf('day').add(maxTravel.travel_time, 'minutes').format('HH:mm')
        : this.$t('not_specified')
    },

    /**
     * Computed value for Max Travel KM (Distance).
     * @returns {String}
     */
    maxTravelKM() {
      if (isNil(this.booking.travel_time)) return this.$t('not_specified')
      const maxTravel = this.booking.travel_time

      return !isNil(maxTravel) ? maxTravel.travel_distance : this.$t('not_specified')
    },

    /**
     * Returns boolean whether if the booking type is text translation.
     *
     * @returns {boolean}
     */
    isBookingTypeTextTranslation() {
      return String(this.booking.type) === 'text_translation'
    },

    /**
     * Returns boolean whether if the booking type is text translation trans target.
     *
     * @returns {boolean}
     */
    isTransTargetBooking() {
      return this.isBookingTypeTextTranslation && this.booking?.text_translation?.is_trans_target
    },

    /**
     * Returns value to determine if the booking has speficic message and files.
     *
     * @returns {boolean}
     */
    hasMessage() {
      return !isNil(this.booking.messages) && !isEmpty(this.booking.messages)
    },

    /**
     * Returns boolean whether if the booking type is convey.
     *
     * @returns {boolean}
     */
    isBookingTypeConvey() {
      return String(this.booking.type) === 'convey'
    },

    /**
     * Returns boolean whether if the booking is flaged asking price.
     *
     * @returns {boolean}
     */
    isAskingPrice() {
      return (
        this.isBookingTypeTextTranslation &&
        this.booking.text_translation.is_asking_price &&
        ![4, 5, 6, 7, 8, 9, 10, 12, 13].includes(this.booking.status_id)
      )
    },

    /**
     * Returns boolean whether if the booking type is video physical.
     *
     * @returns {boolean}
     */
    isBookingTypeVideoPhysical() {
      return String(this.booking.type) === 'video_physical'
    },

    /**
     *
     **/
    textTranslationReviewFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        // return find(this.booking.text_translation.files, function(o){ return o.pivot.submission_type === 'review'} )
        return this.booking.text_translation.files.filter(function (o) {
          return o.pivot.submission_type === 'review'
        })
      }

      return []
    },
    textTranslationApproximateWordsCount() {
      if (this.booking.text_translation?.words_amount > 0) {
        return this.booking.text_translation?.words_amount
      }
      return this.textTranslationReviewFiles.reduce((total, file) => {
        const wordsCount = file.pivot.words_count ?? 0
        return total + wordsCount
      }, 0)
    },
    /**
     *
     **/
    textTranslationSubmittedForReviewFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        // return find(this.booking.text_translation.files, function(o){ return o.pivot.submission_type === 'review'} )
        return this.booking.text_translation.files.filter(function (o) {
          return o.pivot.submission_type === 'submited_for_review'
        })
      }

      return []
    },

    /**
     *
     **/
    textTranslationCompletedFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        // return find(this.booking.text_translation.files, function(o){ return o.pivot.submission_type === 'review'} )
        return this.booking.text_translation.files.filter(function (o) {
          return o.pivot.submission_type === 'completed'
        })
      }

      return []
    },
    textTranslationPendingFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        // return find(this.booking.text_translation.files, function(o){ return o.pivot.submission_type === 'review'} )
        return this.booking.text_translation.files.filter(function (o) {
          return o.pivot.submission_type === 'review'
        })
      }

      return []
    },
    hasMultipleNonVersionedFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        const filteredFiles = this.booking.text_translation.files.filter((o) => {
          return o.pivot.submission_type === 'review'
        })

        let hasMultipleNonVersionedFiles = false

        filteredFiles.forEach((file) => {
          const nonVersionedFiles = file?.translated_files?.filter((tFile) => tFile?.pivot?.is_versioned === 0)
          if (nonVersionedFiles.length > 1) {
            hasMultipleNonVersionedFiles = true
          }
        })

        return hasMultipleNonVersionedFiles
      }

      return false
    },

    /**
     * Returns boolean whether if it's a shadow booking.
     *
     * @returns {Boolean}
     */
    isShadowBooking() {
      return this.booking.parent_booking_id != null
    },
    /**
     * Returns booking additional field label name.
     *
     * @returns {String}
     */
    bookingAdditionalFieldLabel() {
      if (!isEmpty(this.booking.additional_field_label)) return this.booking.additional_field_label
      if (!isEmpty(this.bcfConfig.additional_field_label)) return this.bcfConfig.additional_field_label
      return 'Additional Field'
    },

    bookingTranslator() {
      if (this.booking.status.code == 'planned') {
        return this.booking.planned_translator.translator
      } else {
        return this.booking.assigned_translator
      }
    },

    isTransTargetLanguage() {
      return this.booking?.text_translation?.is_trans_target
    },

    bookingTranslatorLanguagePairLevel() {
      if (!isEmpty(this.booking.language_pair_levels)) {
        return this.booking.language_pair_levels
          .map((languagePairLevel) => this.translateLanguagePairLevelCode(languagePairLevel.code))
          .toString()
      }
      return ''
    },
    /**
     * @return {Boolean}
     */
    isInstantBooking() {
      return this.isBookingTypeTextTranslation && this.booking.text_translation?.is_instant
    },
    /**
     * @return {Boolean}
     */
    isProofReadBooking() {
      return this.isBookingTypeTextTranslation && this.booking.text_translation?.is_proof_reading
    },
    /**
     * @return {String}
     */
    subtype() {
      if (this.isInstantBooking) {
        return this.$t('tt_label_instant')
      } else if (this.isProofReadBooking) {
        return this.$t('tt_label_proof_read')
      }

      return ''
    },
    /**
     * @return {String}
     */
    authToken() {
      const appToken = appStorage.get(LS.TOKEN)
      const token = appToken.access_token ?? appToken
      return token
    },
    textTranslationBookingDeliveryMethod() {
      if (this.booking.text_translation?.deliver_with === 'dt_web') {
        return this.$t('tt_on_web')
      } else if (this.booking.text_translation?.deliver_with === 'dt_email') {
        return this.$t('tt_on_email')
      } else if (this.booking.text_translation?.deliver_with === 'tt_mail') {
        return this.$t('tt_mail')
      }

      return ''
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapActions('booking', ['getBookingDetails']),
    ...mapActions('textTranslation', ['startJob']),

    isEmpty,
    formatTime,

    downloadSelectedFile(item) {
      this.is_waiting = true
      let o = {
        params: `path=${item.path}&display_name=${item.display_name}`,
        filename: item.display_name
      }

      let url = `${API.STORAGE}/get?${o.params}`

      return axiosInstance.get(url).then((r) => {
        this.forceDownloadFileUrl(r.data.data.url, o.filename)
      })
    },

    /**
     * Helper methodss for handling download files to s3
     */
    openFile(item) {
      showFile(item)
    },

    forceDownloadFileUrl(url, filename) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          this.is_waiting = false
        })
        .catch(console.error)
    },

    addDotsForLongerFileName(string, limit) {
      let dots = '...'
      let fileExt = string.split('.')
      if (fileExt[0].length > limit) {
        // you can also use substr instead of substring
        string = fileExt[0].substring(0, limit) + dots + fileExt[1]
      }

      return string
    },

    handlePriceListLinkClick(bookingId, event) {
      event.preventDefault()
      this.loading = true
      apiCall(
        {
          url: `${FINANCIAL}/${bookingId}/salary-setting`,
          method: 'GET'
        },
        axiosInstance
      )
        .then((r) => {
          const priceLink = r.data.data.salaries_setting.price_link
          priceLink ? window.open(priceLink, '_blank') : showSmallErrorNotif(this.$t('price_list_not_found'))
        })
        .catch((e) => {
          showSmallErrorNotif(e)
        })
        .finally(() => {
          this.loading = false
        })
    },

    bookingStartTime() {
      return this.booking?.due_time ?? ''
    },

    bookingEndTime() {
      let willEndAt = this.booking?.will_end_at ?? ''
      let endedAT = this.booking?.ended_at ?? ''
      let endTime = endedAT ? endedAT : willEndAt
      return endTime ? this.formatTime(endTime) : ''
    },

    /**
     * Handler when the Print button was clicked.
     *
     * @returns {void}
     */
    handleClickPrint() {
      if (!isNil(window) && !isNil(window.print) && isFunction(window.print)) {
        window.print()
      }
    },

    /**
     * Handler when the Close button was clicked.
     *
     * @returns {void}
     */
    handleClickClose() {
      this.close()
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @params {object} booking - current target booking.
     * @returns {void}
     */
    open(booking, openTranslationModeModal = false, forceOpenModeModal = true) {
      this.getBookingDetails({
        id: booking.id,
        bookingStatus: booking.status.code,
        additionalInclude: ['feedback'],
        append: 'translated_files_count,rejected_files_count,manual_submission_enabled'
      })
        .then((response) => {
          this.booking = response?.data?.data?.booking
          this.isShowModal = true

          this.$nextTick(() => {
            if (openTranslationModeModal && forceOpenModeModal) {
              if (booking.text_translation.mode_of_submission === 'manual') {
                this.toggleTranslationModesDialog(3)
              } else if (booking.text_translation.mode_of_submission === 'trans_target') {
                this.handleFileClick(this.textTranslationReviewFiles[0], true)
              } else {
                this.toggleTranslationModesDialog(1)
              }
            }
          })
        })
        .finally(() => (this.loading = false))
    },

    // function for open learn more video
    openVideoLink() {
      let storedLocale = localStorage.getItem('app_language')
      storedLocale = !isNil(storedLocale) ? storedLocale : 'se'
      const url =
        storedLocale === 'en'
          ? 'https://vimeo.com/910399113/dfe1c92b74?share=copy'
          : 'https://vimeo.com/910394944/81f91937fd?share=copy'
      window.open(url, '_blank')
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will close the modal.
     *
     * @returns {void}
     */
    close() {
      this.isShowModal = false
    },

    /**
     * Helper method for formatting Translator level values.
     *
     * @param {int|string} levelId - value to be formatted.
     * @returns {string}
     */
    formatTranslatorLevel(levelId) {
      // Attempt to find the equivalent key.
      let key = findKey(getTranslatorLevels(), (id) => id === parseInt(levelId))

      if (!isNil(key)) {
        // If Key exists, return the translated verbiage.
        return this.$t(`translator_level_${key.toLowerCase()}`)
      }

      // else {
      //   throw new Error('Key finding failed.');
      // }

      return ''
    },

    /**
     * Helper method for formatting the gender value to be
     * able to display it properly.
     *
     * @param {string|*} gender
     * @returns {string}
     */
    formatGender(gender) {
      return gender === null || includes(gender, ',') ? `${this.$t('male')}, ${this.$t('female')}` : this.$t(gender)
    },

    /**
     * Helper methods for handling download files to s3
     */
    handleFileClick(item, review = false) {
      if (this.isTransTargetLanguage && review) {
        let storedLocale = localStorage.getItem('app_language')
        storedLocale = !isNil(storedLocale) ? storedLocale : 'se'
        window.open(
          `${this.transTargetUrl}/go-to-booking?booking_id=${this.booking.id}&locale=${storedLocale}&token=${this.authToken}`,
          '_blank'
        )
        return
      }

      if (
        this.booking.status.code === 'assigned' &&
        item.pivot.submission_type === 'review' &&
        this.textTranslationSubmittedForReviewFiles.length === 0
      ) {
        let payload = {
          id: this.booking.text_translation.id,
          booking_id: this.booking.id
        }
        this.startJob(payload).then(() => {
          download(item)
        })
      } else {
        download(item)
      }
    },

    /**
     * helpers to check file is empty or not
     */
    empty(v) {
      return isEmpty(v) || isNil(v)
    },

    generateUrl(bookingVideo) {
      if (!isNil(bookingVideo.url) && bookingVideo.url.substring(0, 4) === 'http') {
        return bookingVideo.url
      } else {
        return '//' + bookingVideo.url
      }
    },

    showDirectSalaryCard(booking) {
      if (!isEmpty(booking)) {
        return !!(['assigned', 'started', 'completed'].includes(booking.status.code) && booking.direct_salary)
      }
    },

    isValidBookingAdditionalField(additionalFieldValue) {
      return !isNil(additionalFieldValue) && additionalFieldValue !== ''
    },
    /**
     * @return {string}
     */
    translateLanguagePairLevelCode(code) {
      switch (code) {
        case 'certified':
          return this.$t('pair_level_certified_interpreter')
        case 'senior':
          return this.$t('pair_level_senior_interpreter')
        case 'non-certified':
          return this.$t('pair_level_non_certified_interpreter')
        default:
          return code
      }
    },

    // check if the given date is within 4 days
    isWithinFourDays(deadline) {
      if (moment(deadline).isSameOrBefore(moment().add(3, 'days'), 'day')) {
        return this.getBem(this.blockClass, 'table-value') + ' text-color-danger'
      } else {
        return this.getBem(this.blockClass, 'table-value')
      }
    },
    toggleTranslationModesDialog(step = 1) {
      this.$refs.translationModesSetupModal.toggleTranslationModesDialog(step)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';

.base-translator-booking-details-modal {
  margin-top: 60px;
  margin-bottom: 60px;

  .n-card-header {
    &__main {
      text-align: center;
      margin-left: 10px;
    }
  }
}

.translator-booking-details-modal {
  width: 55%;
  a,
  a:hover,
  a:focus,
  a:visited,
  a:active {
    color: var(--app-primary-color);
    text-decoration: underline;
  }

  &__direct-salary {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 15px;
    word-break: break-word;
  }

  &__table {
    width: 80%;
    margin: 0 auto;

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      &.docs-list-label {
        align-content: baseline;
        padding-top: 12px;
      }
      span {
        word-break: break-word;
      }
    }

    &-value {
      .el-tag--danger {
        background: rgba(252, 204, 204, 1);
        color: black;
        margin-right: 8px;
        margin-top: 4px;
      }
      .pending-files {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        .item {
          align-content: space-evenly;
        }
        .outlined {
          border: 1px solid !important;
          color: #231f20;
          padding: 6px 12px;
        }
      }
      &-translator-levels,
      &-translator-levels > li {
        font-size: 11px;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .el-button.link {
        padding: 5px 20px !important;

        img {
          margin-right: 4px;
          width: 18px;
        }
      }

      .row {
        display: flex;
        justify-content: center;
      }

      .nonversioned-files-info {
        display: flex;
        flex-direction: row;
      }

      .el-button.learn-more-btn {
        height: 32px;
        padding: 0px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #211f20;
        color: #211f20;
        background: rgba(255, 255, 255, 0);
      }

      &-translator-levels > li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .el-card {
      margin-bottom: 25px;
      white-space: pre-wrap;
      word-break: break-word;
    }

    .files-list {
      list-style: none;
    }

    .rejected-docs-tag {
      margin-top: 5px;
      margin-right: 8px;
    }
  }
}

// **************************************************
// Extra Small Viewport
// **************************************************
@media (max-width: $screen-xs-max) {
  .wordArrange {
    word-break: normal;
  }
  .translator-booking-details-modal {
    .el-dialog {
      width: 90%;
    }

    &__direct-salary-icon {
      margin-left: 65px;
    }
  }
}

// **************************************************
// Small Viewport
// **************************************************
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .translator-booking-details-modal {
    .el-dialog {
      width: 90%;
    }
  }
}

// **************************************************
// Medium Viewport
// **************************************************
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .translator-booking-details-modal {
    .el-dialog {
      width: 75%;
    }
  }
}

@include print-booking-details('translator');
</style>
