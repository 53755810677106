/*
|--------------------------------------------------------------------------
| Store > Booking > Getters
|--------------------------------------------------------------------------
|
| This file is where the Module's getter methods are registered.
|
| Feel free to edit this file if you have getter methods you want
| to include.
|
*/
// import intersection from 'lodash/intersection';
//
export default {
  // --------------------------------------------------
  // Customer Normal Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  customerNormalBookingListData: (state) => state.customerNormalBookingList.data,

  /**
   * @returns {Object}
   */
  customerNormalBookingListPagination: (state) => state.customerNormalBookingList.pagination,

  /**
   * @returns {Object}
   */
  customerTotalNumberBookings: (state) => state.customerTotalBookings,

  /**
   * @returns {Boolean}
   */
  isLoadingCustomerNormalBookingList: (state) => state.customerNormalBookingList.loading,

  // --------------------------------------------------
  // Customer and Translator Other Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  customerAndTranslatorOtherBookingListData: (state) => state.customerAndTranslatorOtherBookingList.data,

  /**
   * @returns {Object}
   */
  customerAndTranslatorOtherBookingListPagination: (state) => state.customerAndTranslatorOtherBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingCustomerAndTranslatorOtherBookingList: (state) => state.customerAndTranslatorOtherBookingList.loading,

  // --------------------------------------------------
  // Customer Emergency Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  customerEmergencyBookingListData: (state) => state.customerEmergencyBookingList.data,

  /**
   * @returns {Object}
   */
  customerEmergencyBookingListPagination: (state) => state.customerEmergencyBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingCustomerEmergencyBookingList: (state) => state.customerEmergencyBookingList.loading,

  // --------------------------------------------------
  // Customer Historic Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  customerHistoricBookingListData: (state) => state.customerHistoricBookingList.data,

  /**
   * @returns {Object}
   */
  customerHistoricBookingListPagination: (state) => state.customerHistoricBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingCustomerHistoricBookingList: (state) => state.customerHistoricBookingList.loading,

  // --------------------------------------------------
  // Translator Potential Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  translatorPotentialBookingListData: (state) => state.translatorPotentialBookingList.data,

  /**
   * @returns {Object}
   */
  translatorPotentialBookingListPagination: (state) => state.translatorPotentialBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingTranslatorPotentialBookingList: (state) => state.translatorPotentialBookingList.loading,

  // --------------------------------------------------
  // Translator Presumptive Potential Booking List
  // --------------------------------------------------

  translatorPresumptivePotentialBookingListData: (state) => state.translatorPresumptivePotentialBookingList.data,

  isLoadingTranslatorPresumptivePotentialBookingList: (state) =>
    state.translatorPresumptivePotentialBookingList.loading,

  // --------------------------------------------------
  // Translator Normal Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  translatorNormalBookingListData: (state) => state.translatorNormalBookingList.data,

  /**
   * @returns {Object}
   */
  translatorNormalBookingListPagination: (state) => state.translatorNormalBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingTranslatorNormalBookingList: (state) => state.translatorNormalBookingList.loading,

  // --------------------------------------------------
  // Translator Historic Booking List
  // --------------------------------------------------

  /**
   * @returns {Array}
   */
  translatorHistoricBookingListData: (state) => state.translatorHistoricBookingList.data,

  /**
   * @returns {Object}
   */
  translatorHistoricBookingListPagination: (state) => state.translatorHistoricBookingList.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingTranslatorHistoricBookingList: (state) => state.translatorHistoricBookingList.loading,

  // --------------------------------------------------
  // Langauge Opts
  // --------------------------------------------------

  /**
   * @returns {Object}
   */
  languageOpts: (state) => state.languageOpts.data,

  languageIsoCodes: (state) => state.languageIsoCodes,

  deepLLanguagesOpts: (state) => state.deepLLanguagesOpts.data,

  recruitmentLanguageOpts: (state) => state.recruitmentLanguageOpts.data,

  countryOpts: (state) => state.countryOpts,

  languageCountries: (state) => state.languageCountries,

  selectedLanguageName: (state) => state.languageOpts.data[state.form.language],

  translateLanguageId: (state, id) => state.languageOpts.data[id],

  /**
   * @returns {Boolean}
   */
  isLoadingLanguageOpts: (state) => state.languageOpts.loading,

  // --------------------------------------------------
  // Booking Confirm related
  // --------------------------------------------------

  /**
   * @returns {Object}
   */
  createdBooking: (state) => state.createdBooking,

  /**
   * @returns {Object}
   */
  bcfConfig: (state) => state.bcfConfig,

  /**
   * @returns {Object}
   */
  confirmSuggestions: (state) => state.confirmSuggestions,

  /**
   * @returns {int}
   */
  availableTranslators: (state) => state.availableTranslators,
  /**
   * @returns {int}
   */
  assignmentProbability: (state) => state.assignmentProbability,

  skipFirstConfirmationStep: (state) => state.skipFirstConfirmationStep,
  mustGoToFirstConfirmationStep: (state) => state.mustGoToFirstConfirmationStep,

  /**
   * returns {Object}
   */
  appointmentTemplates: (state) => state.appointmentTemplates,

  /**
   * @returns {Object}
   */
  getAvailableTranslatorsByPreferences: (state) => state.availableTranslatorsByPreferences,

  /**
   * @returns {Boolean}
   */
  isNotSufficientTranslators: (state) => state.availableTranslators < 10,

  /**
   * @returns {int}
   */
  selectedLanguage: (state) => state.form.language,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking of translators in general.
   *
   * IMPORTANT: Don't be confused, property: count's value is actually boolean.
   *
   * @returns {boolean}
   */
  hasShortageInTranslator: (state) => state.translatorShortages.count,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking of specific translators.
   *
   * @returns {boolean}
   */
  hasShortageInSpecificTranslator: (state) => state.translatorShortages.specific_translator,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking with specific countries.
   *
   * @returns {boolean}
   */
  hasShortageInSpecificCountry: (state) => state.translatorShortages.country,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking of specific gender.
   *
   * @returns {Boolean}
   */
  hasShortageInTranslatorGender: (state) => state.translatorShortages.gender,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking of specific translator levels.
   *
   * @returns {Boolean}
   */
  hasShortageInTranslatorLevel: (state) => state.translatorShortages.translator_level,

  /**
   * Returns boolean value for determining if there is a shortage
   * regarding Booking of language.
   *
   * @returns {Boolean}
   */
  hasShortageInLanguage: (state) => state.translatorShortages.language,

  /**
   * Returns boolean value for determining if there are shortage
   * regarding Booking of physical (type) bookings.
   *
   * @returns {Boolean}
   */
  hasShortageInTranslatorPhysical: (state) => state.translatorShortages.physical,

  /**
   * @returns {boolean}
   */
  isShowBookingSpecificPreferenceQuestions(state) {
    // The check !==null means there has been a value passed.
    return (
      state.translatorShortages.specific_translator !== null ||
      state.translatorShortages.gender !== null ||
      state.translatorShortages.translator_level !== null ||
      state.translatorShortages.country !== null ||
      // || !isTranslatorLevelsDefinedComplete
      state.translatorShortages.count ||
      state.form.message_files !== null
    )
  },

  hasBookingConfirmQuestions(state) {
    let ignoreProperties = ['was_asked_exclude_translator']
    return Object.keys(state.confirmForm.booking_specific_preference)
      .filter((item) => !ignoreProperties.includes(item))
      .some((item) => {
        return item.startsWith('was_asked') && state.confirmForm.booking_specific_preference[item] == true
      })
  },

  hasBookingConfirmFields(state) {
    let fields = ['booker_name_enabled', 'staff_name_enabled', 'reference_enabled', 'additional_field_enabled']

    return fields.some(function (key) {
      return state.bcfConfig[key] == true
    })
  },

  isTranslatorCountEnabledForEmergency(state) {
    return state.bcfConfig['translator_count_enabled'] == true
  },

  // --------------------------------------------------
  // Misc
  // --------------------------------------------------

  /**
   * @returns {Boolean}
   */
  isLoadingValidate: (state) => state.isLoadingValidate,

  /**
   * @returns {Boolean}
   */
  isConfirmBooking: (state) => state.isConfirmBooking,

  isLoadingPaymentSetup: (state) => state.isLoadingPaymentSetup,

  /**
   * @returns {Boolean}
   */
  isLoadingSubmit: (state) => state.isLoadingSubmit,

  /**
   * @returns {Boolean}
   */
  isShowBookingConfirmModal: (state) => state.isShowBookingConfirmModal,

  isShowRecurringBookingConfirmModal: (state) => state.isShowRecurringBookingConfirmModal,

  isShowMultipleBookingConfirmModal: (state) => state.isShowMultipleBookingConfirmModal,

  /**
   * @returns {string}
   */
  bookingConfirmModalUsedBy: (state) => state.bookingConfirmModalUsedBy,

  /**
   *
   */
  isLoadingUpcomingBookingList: (state) => state.customerUpcomingBookingList.loading,

  /**
   * @returns {Array}
   */
  customerUpcomingBookingListData: (state) => state.customerUpcomingBookingList.data,

  /**
   * @returns {boolean}
   */
  isConfirmFormHalted: (state) => state.isConfirmFormHalted,

  translatorExpenseSettings: (state) => state.expense_settings,

  acceptRejectBookingLoading: (state) => state.acceptRejectBookingLoading,

  bookingDefaultLoading: (state) => state.bookingDefaultLoading,

  customersList: (state) => state.customersList,

  departmentsList: (state) => state.departmentsList,

  companyList: (state) => state.companyList,

  customerCategoryList: (state) => state.customerCategoryList,

  adminUserList: (state) => state.adminUserList,

  freshTimeSlot: (state) => state.freshTimeSlot,

  nonVocalLanguages: (state) => state.nonVocalLanguages,
  isNonVocalLanguageSelected: (state) => {
    const langName = state.languageOpts.data[state.form.language]
    return state.nonVocalLanguages.includes(langName)
  },
  languageCallUs: (state) => state.languageCallUs,

  formPayload: (state) => state.formPayload
}
