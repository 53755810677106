/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorPotentialBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { loadAll } from '@/modules/helpers/vuexActions.js'

export default function (context, payload = {}) {
  let url = `${API.BOOKINGS}/potential`
  let loading = 'setTranslatorPotentialBookingListLoading'
  let include = [
    // 'customer',
    // 'translator_levels',
    'specific_translators',
    // 'excluded_translators',
    // 'convey.files',
    // 'text_translation.files',
    // 'messages.files',
    'video_booking',
    'booking_group.bookings',
    // 'translator_trainings',
    'booking_time_slots.translator.translator_unavailable_times'
  ]

  context.commit(loading, true)

  const append = ['acceptable_booking_dates']

  include = include.join(',')

  const method = 'GET'
  const fields = [
    'id,customer_id,due,duration,from_language_id,to_language_id,status_id,is_immediate,gender,type,created_at,reference,booker_name,additional_field_label,additional_field,staff_name,instructions,address,ended_at,will_end_at,booking_group_id,parent_booking_id,staff_phone'
  ].join(',')

  let query_params = {
    include,
    // 'paginator': 'simple',
    'paginator': payload.paginator,
    'fields[bookings]': fields,
    append
  }

  let options = {
    query_params: { ...query_params, ...payload.query_params },

    on_finally(ctx) {
      ctx.commit(loading, false)
    }
  }

  return loadAll(this, context, url, method, '', options, axiosInstance)
}
