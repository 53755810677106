/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorHistoricBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { bookingsListFilterOptions, loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store'

/**
 * Action to load translator historic bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  context.commit('setTranslatorHistoricBookingListLoading', true)

  const url = `${API.BOOKINGS}/historic`
  const method = 'GET'

  const include = [
    // 'customer',
    // 'feedback',
    // 'translator_levels',
    // 'assigned_translator',
    'video_booking',
    'booking_time_slots.translator.translator_unavailable_times'
    // 'messages.files',
    // 'convey.files',
    // 'text_translation.files',
    // 'issues.type',
    // 'translator_trainings',
    // 'direct_salary'
  ].join(',')

  const fields = [
    'id,customer_id,due,duration,from_language_id,status_id,is_immediate,gender,type,created_at,reference,booker_name,additional_field_label,additional_field,staff_name,instructions,address,ended_at,will_end_at,session_time,parent_booking_id,staff_phone'
  ].join(',')
  const topControls = await store.getters['bookingList/topControls']
  let statuses = topControls?.booking_statuses?.toString()

  let options = {
    query_params: {
      include,
      'fields[bookings]': fields,
      'filter[status_id]': statuses
    },

    on_finally(ctx) {
      ctx.commit('setTranslatorHistoricBookingListLoading', false)
      ctx.commit('setBookingFilterQueryForExport', assignIn(options.query_params, filterQueries))
    }
  }

  let emergency = topControls?.is_immediate ?? payload?.is_immediate ?? null
  console.log('emergency', emergency)
  if (emergency) {
    options.query_params['filter[is_immediate]'] = emergency
  } else {
    delete options.query_params['filter[is_immediate]']
  }
  let type = topControls?.type ?? payload?.type ?? null
  if (type) {
    payload.filter_list_query = type
  }

  if (payload?.includeBookingType) {
    options.query_params['filter[type]'] = payload.includeBookingType
  }

  const filterQueries = bookingsListFilterOptions(payload, {
    exclude: ['customer_id']
  })

  assignIn(options.query_params, filterQueries)

  // fix for CS-741
  if (options.query_params['filter[id]'] !== undefined && options.query_params['filter[id]'] !== null) {
    delete options.query_params['filter[status_id]']
  }

  return loadAll(this, context, url, method, 'setTranslatorHistoricBookingList', options, axiosInstance)
}
